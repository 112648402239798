<!-- 注册页面 -->
<template>
  <headBackBar iCustomizdde="true">
    <template #default>
      <span>
        {{ $fanyi("注册") }}
      </span>
    </template>
  </headBackBar>
  <div class="coBranding">
    <img :src="require('@/assets/icon/app1688row.png')" alt="" />
  </div>
  <!-- 注册区域 -->
  <div class="main" v-if="step != 3">
    <div class="register">
      <van-form ref="formRulesooo">
        <!-- 登录名 -->
        <van-cell-group inset>
          <van-field
            :class="{
              errinput: formRules.username == 0 && step == 1,
              sureinput: step == 2,
            }"
            @blur="validateField('username', 'formRulesooo')"
            v-model.trim="form.username"
            error
            required
            name="username"
            label-align="top"
            :label="$fanyi('登录名')"
            :rules="[
              {
                required: true,
                message: $fanyi(
                  '请输入2位-24位字符，允许特殊符号，不能使用空格'
                ),
                trigger: 'onBlur',
              },
              { validator: validatorusername, trigger: 'onBlur' },
            ]"
          >
            <template #right-icon>
              <van-icon
                name="success"
                v-if="formRules.username == 2 && step == 1"
              />
              <van-icon
                name="cross"
                v-if="formRules.username == 0 && step == 1"
              />
            </template>
          </van-field>
          <!-- 姓氏 -->
          <van-field
            v-model.trim="form.last_name"
            :class="{
              errinput: formRules.last_name == 0 && step == 1,
              sureinput: step == 2,
            }"
            name="last_name"
            error
            required
            @blur="validateField('last_name', 'formRulesooo')"
            label-align="top"
            :label="$fanyi('姓氏')"
            :rules="[
              {
                required: true,
                message: $fanyi('请输入1位-24位字符，允许特殊符号'),
                trigger: 'onBlur',
              },
              // { validator: validatorlast_name, trigger: 'onBlur' },
            ]"
          >
            <template #right-icon>
              <van-icon
                name="success"
                v-if="formRules.last_name == 2 && step == 1"
              />
              <van-icon
                name="cross"
                v-if="formRules.last_name == 0 && step == 1"
              />
            </template>
          </van-field>
          <!-- 名称 -->
          <van-field
            v-model.trim="form.realname"
            :class="{
              errinput: formRules.realname == 0 && step == 1,
              sureinput: step == 2,
            }"
            name="realname"
            error
            required
            @blur="validateField('realname', 'formRulesooo')"
            label-align="top"
            :label="$fanyi('名称')"
            :rules="[
              {
                required: true,
                message: $fanyi('请输入1位-24位字符，允许特殊符号'),
                trigger: 'onBlur',
              },
              // { validator: validatorrealname, trigger: 'onBlur' },
            ]"
          >
            <template #right-icon>
              <van-icon
                name="success"
                v-if="formRules.realname == 2 && step == 1"
              />
              <van-icon
                name="cross"
                v-if="formRules.realname == 0 && step == 1"
              />
            </template>
          </van-field>
          <!-- 公司名 -->
          <van-field
            v-model.trim="form.company"
            :class="{
              errinput: formRules.company == 0 && step == 1,
              sureinput: step == 2,
            }"
            name="company"
            error
            @blur="validateField('company', 'formRulesooo')"
            label-align="top"
            :label="$fanyi('公司名')"
          >
            <template #right-icon>
              <van-icon
                name="success"
                v-if="formRules.company == 2 && step == 1"
              />
              <van-icon
                name="cross"
                v-if="formRules.company == 0 && step == 1"
              />
            </template>
          </van-field>
          <!-- 电子邮箱 -->
          <van-field
            v-model.trim="form.email"
            :class="{
              errinput: formRules.email == 0 && step == 1,
              sureinput: step == 2,
            }"
            error
            required
            name="email"
            @blur="validateField('email', 'formRulesooo')"
            label-align="top"
            :label="$fanyi('电子邮箱')"
            :rules="[
              {
                type: 'email',
                trigger: 'onBlur',
                required: true,
                message: $fanyi(
                  '请输入您正常使用的邮箱地址，将用于接收重要的验证和通知'
                ),
              },
              {
                validator: validatoremail,
                trigger: [' onBlur', ' onChange'],
              },
            ]"
          >
            <template #right-icon>
              <van-icon
                name="success"
                v-if="formRules.email == 2 && step == 1"
              />
              <van-icon name="cross" v-if="formRules.email == 0 && step == 1" />
            </template>
          </van-field>
          <!-- 手机号 -->
          <van-field
            v-model.trim="form.mobile"
            type="number"
            error
            :class="{
              errinput: formRules.mobile == 0 && step == 1,
              sureinput: step == 2,
            }"
            required
            name="mobile"
            label-align=" top"
            @blur="validateField('mobile', 'formRulesooo')"
            :label="$fanyi('手机号')"
            :rules="[
              {
                required: true,
                message: $fanyi(
                  '请输入正常使用的手机号，将用于接收重要的验证和通知'
                ),
                trigger: 'onBlur',
              },
              {
                validator: validatormobile,
                trigger: 'onBlur',
              },
            ]"
          >
            <template #right-icon>
              <van-icon
                name="success"
                v-if="formRules.mobile == 2 && step == 1"
              />
              <van-icon
                name="cross"
                v-if="formRules.mobile == 0 && step == 1"
              />
            </template>
          </van-field>
          <!-- 座机 -->
          <van-field
            class="Plane"
            v-model.trim="form.tel"
            type="number"
            :class="{
              errinput: formRules.tel == 0 && step == 1,
              sureinput: step == 2,
            }"
            error
            name="tel"
            @blur="validateField('tel', 'formRulesooo')"
            label-align="top"
            :label="$fanyi('座机')"
          >
            <template #right-icon>
              <van-icon name="success" v-if="form.tel && step == 1" />
            </template>
          </van-field>
          <!-- 密码 -->
          <van-field
            v-model.trim="form.password"
            error
            :class="{ sureinput: step == 2 }"
            required
            name="password"
            :type="data.passwordtype"
            @blur="
              form.Passwordconfirmation && form.password
                ? validateField('Passwordconfirmation')
                : ''
            "
            label-align="top"
            :label="$fanyi('密码')"
            :rules="[
              {
                required: true,
                message: $fanyi(
                  '请输入 6 到 16 位的密码,可由字母数字及特殊符号组成，不支持空格'
                ),
                trigger: ['onBlur', 'onChange'],
              },
              {
                validator: validatorpassword,
                trigger: 'onBlur',
              },
            ]"
          >
            <template #right-icon>
              <img
                src="../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                alt=""
                v-if="data.showpassword"
                @click="
                  data.passwordtype = '';
                  data.showpassword = !data.showpassword;
                "
              />
              <img
                src="../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                alt=""
                v-else
                @click="
                  data.passwordtype = 'password';
                  data.showpassword = !data.showpassword;
                "
              />
            </template>
          </van-field>
          <!-- 密码确认 -->
          <van-field
            v-if="step == 1"
            v-model.trim="form.Passwordconfirmation"
            :class="{ sureinput: step == 2 }"
            error
            required
            name="Passwordconfirmation"
            label-align="top"
            :label="$fanyi('密码确认')"
            :type="data.showwordtype"
            :rules="[
              {
                required: true,
                trigger: 'onBlur',
                message: $fanyi('请再次输入密码'),
              },
              {
                validator: validatorPasswordconfirmation,
                trigger: 'onBlur',
              },
            ]"
          >
            <template v-if="step == 1" #right-icon>
              <img
                src="../../assets/register/pskiqjtniew8qn40zy6z27vmuj8pz83nlpk1ff25a83-0550-43e4-b065-7ac1b43e24c0.png"
                alt=""
                v-if="data.showword"
                @click="
                  data.showwordtype = '';
                  data.showword = !data.showword;
                "
              />
              <img
                src="../../assets/register/psf6jdb4b7bul6anwcjhoyc6okp25iqe2fef905-6352-437e-987e-3612fa48c9c9.png"
                alt=""
                v-else
                @click="
                  data.showwordtype = 'password';
                  data.showword = !data.showword;
                "
              />
            </template>
          </van-field>
          <!-- 验证码 -->
          <!-- 未完成 这里为了测试暂时注释 -->
          <!-- type="number" -->
          <van-field
            v-if="step == 1"
            v-model.trim="form.code"
            error
            required
            :name="$fanyi('验证码')"
            label-align="top"
            :label="$fanyi('验证码')"
            :rules="[
              {
                required: true,
                message: $fanyi('请填写验证码'),
                trigger: 'onBlur',
              },
              {
                validator: validatorcode,
                trigger: 'onBlur',
              },
            ]"
            :class="{ sureinput: step == 2 }"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                v-if="show == true"
                @click="sendSMS"
                >{{ $fanyi("获取验证码") }}</van-button
              >
              <div v-else>{{ Countdown }}s</div>
            </template>
          </van-field>
          <!-- 验证码 -->
        </van-cell-group>
      </van-form>
      <!-- 提交 -->
      <div class="submit" v-if="step == 1" @click="nextstep">
        {{ $fanyi("同意以下协议，进入下一步") }}
      </div>
      <div class="submit" v-if="step == 2" @click="register">
        {{ $fanyi("确认无误，立即注册") }}
      </div>
      <div v-if="step == 1" class="agreement">
        <input v-model="checked" type="checkbox" /> {{ $fanyi("RAKUMART的") }}
        <span @click="openPopup('userAgreement')">{{ $fanyi("用户协议") }}</span
        >{{ $fanyi("以及") }}
        <span @click="openPopup('privacyPolicy')">{{
          $fanyi("隐私政策")
        }}</span>
      </div>
      <div
        v-if="step == 2"
        @click="(step = 1), (document.documentElement.scrollTop = 0)"
        class="goback"
      >
        {{ $fanyi("返回上一步") }}
      </div>
    </div>
  </div>

  <!-- 注册完成 -->
  <div class="main" v-if="step == 3">
    <div class="content">
      <div class="top-title">
        <img src="../../assets/register/dui.png" alt="" />
      </div>
      <div class="top-text">
        <p>{{ $fanyi("完成注册") }}</p>
        <p>{{ $fanyi("账号注册完成，非常感谢您的注册") }}</p>
      </div>
      <div class="gologin" @click="goindex()">{{ $fanyi("立即登录") }}</div>
      <div class="goindex" @click="goindex('index')">
        {{ $fanyi("回到首页") }}
      </div>
    </div>
  </div>
  <div class="technicalSupport" :class="{ successCss: step == 3 }">
    {{ $fanyi("由1688提供部分技术服务⽀持") }}
  </div>
  <dialogVue
    ref="userAgreement"
    :config="{
      title: 'ラクマート会員利用規約 ',
    }"
  >
    <membershipUtilizationProtocol />
  </dialogVue>
  <dialogVue
    ref="privacyPolicy"
    :config="{
      title: 'プライバシーポリシー ',
    }"
  >
    <privacyPolicyVue />
  </dialogVue>
  <dialogVue
    ref="mdialog"
    :config="{
      title: ' ',
    }"
  >
    <div class="humanMachineVBox">
      <div class="humanMachineVImgBox">
        <img :src="imgBlob" alt="" />
        <span @click="sendSMS">{{ $fanyi("刷新") }}</span>
      </div>
      <div class="humanMachineVBtnBox">
        <input
          type="text"
          v-model="imgRegValue"
          @keydown.enter="fasong"
          placeholder="画像内の文字列をご入力ください"
        />
        <button @click="fasong">
          {{ $fanyi("确认") }}
        </button>
      </div>
    </div>
  </dialogVue>
</template>
<script setup>
import dialogVue from "@/components/Dialog";
import membershipUtilizationProtocol from "@/view/staticPage/view/membershipUtilizationProtocol.vue";
import privacyPolicyVue from "@/view/staticPage/view/privacyPolicy.vue";
import headBackBar from "../../components/headGoBack";
import { Toast } from "vant";
import { getCurrentInstance, ref, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const imgBlob = ref(); //验证码图片
const imgRegValue = ref(); //验证码输入值
// 表单数据
let form = ref({
  mobile: "", // 手机号码
  username: "", // 登录名
  realname: "", // 客户名称
  password: "", // 账号密码
  email: "", // 邮箱
  last_name: "", // 客户姓氏
  company: "", //公司名
  tel: "", // 座机号码
  code: "", // 验证码
  Passwordconfirmation: "", // 密码确认
});
const checked = ref(false);
const step = ref(1);
// 整个表单
const formRulesooo = ref(null);
// nextTick(() => {
//   console.log(formRulesooo.value.validate(form.value.username).then);
// })
// console.log(formRulesooo.validate(form.value.username));
// 校验类型数据 0 错误 1 正常 2 正确
let formRules = ref({
  mobile: 1, // 手机号码
  username: 1, // 登录名
  realname: 1, // 客户真名
  password: 1, // 账号密码
  email: 1, // 邮箱
  tel: 1, // 座机号码
  code: 1, // 验证码
  Passwordconfirmation: 1, // 密码确认
});
// 打开用户协议的两个弹窗
const openPopup = (page) => {
  proxy.$refs[page].open();
};
// 其他数据
const data = ref({
  value: "",
  passwordtype: "password",
  showwordtype: "password",
  showword: true,
  showpassword: true,
});
//
const columns = [
  { text: proxy.$fanyi("手机"), value: "mobile" },
  { text: proxy.$fanyi("邮箱"), value: "email" },
];
const fieldValue = ref("mobile");
const showPicker = ref(false);
const onConfirm = (value) => {
  showPicker.value = false;
  fieldValue.value = value.value;
};
const changeShowFont = (v) => {
  let jieguo = columns.find((item) => {
    return item.value == v;
  });
  return jieguo ? jieguo.text : v;
};
// 字段校验 登录名
const validatorusername = async (value, target) => {
  if (value !== "" && value != undefined) {
    if (!/^[^\s]{2,24}$/.test(value)) {
      return proxy.$fanyi("请输入2位-24位字符，允许特殊符号，不能使用空格");
    }
    let res = await proxy.$api.checkRegisterField({
      field: "username",
      value: value,
    });
    if (res.success == false) {
      form.value.username = "";
      formRules.value.username = 0;
      return proxy.$fanyi("当前登录名已被注册，请使用其他登录名进行注册。");
    }
  }
};
// 姓氏
const validatorlast_name = (value, target) => {
  if (value !== "" && value != undefined) {
    if (!/^[!-~]{1,24}$/.test(value)) {
      return proxy.$fanyi("请输入1位-24位字符，允许特殊符号");
    }
  }
};

// 名称
const validatorrealname = (value, target) => {
  if (value !== "" && value != undefined) {
    if (!/^[!-~]{1,24}$/.test(value)) {
      return proxy.$fanyi("请输入1位-24位字符，允许特殊符号");
    }
  }
};
// 电子邮箱校验
const validatoremail = async (val, target) => {
  if (
    /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/.test(
      val
    ) === false
  ) {
    return proxy.$fanyi("邮箱格式不正确，请输入正确的邮箱格式");
  }
  let res = await proxy.$api.checkRegisterField({
    field: "email",
    value: val,
  });
  if (res.success == false) {
    form.value.email = "";
    formRules.value.email = 0;
    if (res.msg.substr(-5) == "格式不正确") {
      return proxy.$fanyi("邮箱格式不正确，请输入正确的邮箱格式");
    } else {
      return proxy.$fanyi("当前邮箱已被注册，请使用其他邮箱进行注册。");
    }
  }
};
// 手机号校验
const validatormobile = async (val, target) => {
  // if (/^(\w){11}$/.test(val) === false) {
  //   return proxy.$fanyi("您输入的手机号格式不正确，请输入正确的手机号");
  // }

  let res = await proxy.$api.checkRegisterField({
    field: "mobile",
    value: val,
  });
  if (res.success == false) {
    form.value.mobile = "";
    formRules.value.mobile = 0;
    if (res.msg.substr(-5) == "格式不正确") {
      return proxy.$fanyi("您输入的手机号格式不正确，请输入正确的手机号");
    } else {
      return proxy.$fanyi("当前手机号已被注册，请使用其他手机号进行注册。");
    }
  }
};
// 密码
const validatorpassword = (value) => {
  let reg = /^[^\s]{6,16}$/;
  if (!reg.test(value)) {
    return proxy.$fanyi(
      "请输入 6 到 16 位的密码,可由字母数字及特殊符号组成，不支持空格"
    );
  }
};
// 确认密码校验
const validatorPasswordconfirmation = (val) => {
  if (!val) {
    return proxy.$fanyi("请再次输入密码");
  } else if (val !== form.value.password) {
    return proxy.$fanyi("输入密码和前一次输入的不一致");
  }
};
// 验证码校验
const validatorcode = async (val) => {
  if (fieldValue.value == "mobile" && form.value.mobile === "") {
    return proxy.$fanyi("请输入手机号");
  }
  if (fieldValue.value == "email" && form.value.email === "") {
    return proxy.$fanyi("请输入邮箱");
  }
  if (!val) return proxy.$fanyi("请输入验证码");

  let res = await proxy.$api.checkVerificationCode({
    number: fieldValue.value == "mobile" ? form.value.mobile : form.value.email,
    code: val,
  });
  if (!res.success) {
    return proxy.$fanyi(res.msg);
  }
};
// 定义倒计时数据
const show = ref(true);
const Countdown = ref(60);
// 发送验证码
const fasong = () => {
  let list = {
    number: fieldValue.value == "email" ? form.value.email : form.value.mobile,
    captcha: imgRegValue.value,
  };
  Toast.loading({
    message: proxy.$fanyi("加载中..."),
    forbidClick: true,
  });
  proxy.$api.sendVerificationCode(list).then((res) => {
    if (res.code != 0) return Toast.fail(proxy.$fanyi(res.msg));
    show.value = false;
    proxy.$refs.mdialog.show = false;
    // 60秒倒计时
    var timer = setInterval(() => {
      Countdown.value--;
      if (Countdown.value <= 0) {
        show.value = true;
        Countdown.value = 60;
        clearInterval(timer);
      }
    }, 1000);
  });
};
// 打开验证弹窗
const sendSMS = () => {
  if (fieldValue.value == "mobile") {
    if (form.value.mobile === "") {
      Toast(proxy.$fanyi("请输入手机号"));
      return false;
    }
    if (!formRules.value.mobile) {
      Toast(proxy.$fanyi("您输入的手机号格式不正确，请输入正确的手机号"));
      return false;
    }
  }
  if (fieldValue.value == "email") {
    if (form.value.email === "") {
      Toast(proxy.$fanyi("请输入邮箱"));
      return false;
    }
    if (!formRules.value.email) {
      Toast(proxy.$fanyi("邮箱格式不正确，请输入正确的邮箱格式"));
      return false;
    }
  }
  imgRegValue.value = "";
  proxy.$api
    .clientcodeImg({
      rand: Math.floor(Math.random() * 90000) + 10000,
      number:
        fieldValue.value == "email" ? form.value.email : form.value.mobile,
    })
    .then((res) => {
      imgBlob.value = res.data.image;
      //接下来的操作
      proxy.$refs.mdialog.open(
        "",
        () => {},
        () => {}
      );
    });
};
// 失去焦点校验表单 然后改变状态
const validateField = (type, name) => {
  // console.log(type);
  // console.log(formRulesooo.value);
  formRulesooo.value
    .validate(type)
    .then((res) => {
      formRules.value[type] = 2;
    })
    .catch(() => {
      formRules.value[type] = 0;
    });
};
// 点击下一步
const nextstep = () => {
  formRulesooo.value
    .validate()
    .then((res) => {
      if (step.value == 1 && checked.value == false) {
        Toast(proxy.$fanyi("使用条款和隐私政策还没有被同意"));
      } else if (step.value == 1 && checked.value == true) {
        (data.value.passwordtype = ""),
          (data.value.showwordtype = ""),
          (step.value = 2);
        document.documentElement.scrollTop = 0;
      }
    })
    .catch(() => {});
};
// 点击注册
const register = () => {
  formRulesooo.value
    .validate()
    .then((res) => {
      let list = {
        username: form.value.username, //登录名
        password: form.value.password, //密码
        realname: form.value.realname, //真实姓名
        email: form.value.email, //邮箱
        mobile: proxy.$fun.deepClone(form.value.mobile).toString(), //手机号
        tel: form.value.tel,
        last_name: form.value.last_name, //姓氏
        company: form.value.company,
        client_tool: 2,
        code: form.value.code,
      };
      // 清除空值
      for (let i in list) {
        if (!list[i]) {
          delete list[i];
        }
      }
      // 假如是推荐链接进入就把邀请码也放进去
      if (route.query.invite) {
        list.invite = route.query.invite;
      }
      Toast.loading({
        duration: 0,
        message: proxy.$fanyi("加载中..."),
        forbidClick: true,
      });
      proxy.$api.register(list).then((res) => {
        if (res.code != 0) return Toast("登録失敗");
        Toast.clear();
        step.value = 3;
        proxy.$router.push("/registerSuccess");
        document.documentElement.scrollTop = 0;
        //统计注册流量,gtag是index.html上的方法
        gtag("set", "user_data", { email: email });
        Toast.clear();
      });
    })
    .catch(() => {});
};
// 注册完成后去首页登录
const goindex = (type) => {
  if (type == "index") {
    proxy.$fun.toPage("/");
  } else {
    proxy.$store.commit("changeshowLoginDia", () => {
      proxy.$fun.toPage("/");
    });
  }
};
//-----------------------------pageLoad----------------------
if (proxy.$route.name == "registerSuccess") {
  step.value = 3;
}
</script>
<style lang="scss" scoped="scoped">
// @import "@/css/mixin.scss";
.coBranding {
  padding: 20px 0 0;
  text-align: center;
  background: #f6f6f6;
  img {
    width: 690px;
  }
}
.main {
  width: 750px;
  min-height: 1254px;
  background: #f6f6f6;
  padding: 20px 30px 0px 30px;

  :deep() .van-cell:after {
    border-bottom: none !important;
  }
  :deep().van-cell__title {
    width: 100%;
  }
  .Plane {
    :deep().van-cell__title {
      padding-left: 15px;
    }
  }

  .register {
    width: 690px;
    min-height: 1478px;
    background: #ffffff;
    border-radius: 6px;
    padding-bottom: 59px;

    :deep().van-cell {
      flex-direction: column;
      padding: 0px;
      min-height: 170px;
    }

    :deep().van-picker-column__item {
      margin-bottom: 10px;

      font-size: 28px;
    }

    :deep().van-picker__confirm {
      color: #419dfb;
    }

    :deep() .van-cell-group {
      padding: 40px 20px;
    }

    :deep().van-field__body {
      input {
        font-size: 28px;
      }

      input:-webkit-autofill {
        box-shadow: 0 0 0 1000px transparent inset;
      }

      input:-internal-autofill-previewed,
      input:-internal-autofill-selected {
        -webkit-text-fill-color: #333 !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
    }

    :deep() #van-field-44-input {
      width: 350px;
      margin-left: 220px;
    }

    :deep() .van-field__label {
      margin-bottom: 10px !important;
      font-size: 28px;

      font-weight: 400;
      color: #999999;
    }

    :deep() .van-field__control--error::placeholder {
      color: #999999;
      font-size: 25px;
      margin-top: 5px;
    }

    :deep() .van-field__control {
      padding-top: 10px;
    }

    // :deep() #van-field-8-input {
    //   width: 368px;
    //   height: 70px;
    //   background: #FFFFFF;
    //   border: 1px solid #DFDFDF;
    //   border-radius: 6px;

    //   margin-left: 202px;

    // }

    .yanzhngma {
      :deep().van-field__control--error {
        width: 368px;
        // height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;

        margin-left: 202px;
      }
      :deep().van-cell__right-icon {
        display: none;
      }
      // :deep().van-field__left-icon {
      //   height: 70px;
      //   * {
      //     max-height: 70px;
      //   }
      // }
    }

    :deep() #van-field-9-input {
      font-size: 28px;

      font-weight: 400;
      color: #000000;
    }

    :deep().van-icon-arrow-down {
      display: flex;
      margin-right: -50px;
      color: #000000;
      font-size: 20px; //日志
    }

    :deep() .van-field-24-input {
      width: 368px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
    }

    :deep() .van-field__left-icon {
      position: absolute;
      top: 58px;
      // left: -20px;
      z-index: 10;

      .verification {
        width: 190px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        position: relative;
        display: flex;
        padding-left: 20px;
        align-items: center;
        font-size: 28px;
        img {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 10px;
        }
      }
      .verification.smallFont {
        font-size: 20px;
      }
      :deep().van-field-9-input {
        font-size: 28px;
      }
    }

    :deep() .van-field__button {
      .van-button {
        margin-right: -20px;
        font-size: 28px;

        font-weight: 400;
        color: #b4272b;
        background-color: transparent !important;
        border: none;

        .van-button__text {
          font-size: 28px;

          font-weight: 400;
          color: #b4272b;
          background-color: #fff !important;
          border: none;
        }
      }

      position: absolute;
      right: 27px;
    }

    :deep() .van-field__body {
      padding-left: 10px;

      position: relative;

      input {
        width: 570px;
        height: 70px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 0;
        font-size: 28px !important;
        padding-left: 24px;
        display: flex;
        align-items: center;
        color: #999999;
      }
    }

    .errinput {
      :deep() input {
        background: #faf2f2 !important;
        border: 1px solid #b4272b !important;
        border-radius: 6px;
      }
    }

    .sureinput {
      :deep() input,
      input {
        pointer-events: none;
        background: #f2f2f2 !important;
        // border: 1px solid #B4272B !important;
        border-radius: 6px;
      }
    }

    :deep().van-field__right-icon {
      position: absolute;
      right: 38px;

      img {
        width: 24px;
        height: 16px;
      }

      .van-icon-success {
        color: #50c136;
      }

      .van-icon-cross {
        color: #b4272b;
      }
    }

    :deep() .van-field__error-message {
      padding-left: 24px;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 400;
      min-height: 38px;
      color: #b4272b;
    }

    .submit {
      margin: auto;

      width: 570px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;

      font-weight: 400;
      color: #ffffff;
    }

    .goback {
      margin: auto;

      margin-top: 30px;
      width: 570px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;

      font-weight: 400;
      color: #000000;
    }

    .agreement {
      margin-top: 29px;
      display: flex;
      font-size: 24px;

      font-weight: 400;
      text-align: center;
      justify-content: center;
      color: #999999;
      align-items: center;

      input {
        flex: 0 0 24px;
        width: 24px !important;
        height: 24px !important;
        text-align: center;
        // background: #FFFFFF;
        border: 2px solid #dfdfdf;
        border-radius: 4px;
        margin: 0px;
        margin-right: 8px;
        color: #fff;
      }

      // .input[type="checkbox"]:checked::after {
      //   font-size: 30px;
      // }

      span {
        font-size: 24px;
        color: #b4272b;

        font-weight: 400;
      }
    }
  }

  .content {
    width: 690px;
    height: 1300px;
    background: #ffffff;
    border-radius: 6px;
    padding-top: 60px;

    .top-title {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 80px;
        height: 80px;
      }

      margin-bottom: 41px;
    }

    .top-text {
      width: 100%;
      text-align: center;

      p {
        width: 100%;

        &:first-child {
          font-size: 32px;

          font-weight: 600;
          color: #000000;
          margin-bottom: 18px;
        }

        &:last-child {
          font-size: 24px;

          font-weight: 400;
          color: #999999;

          font-weight: 400;
          color: #999999;
        }
      }
    }

    .gologin {
      width: 570px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 66px;
      font-size: 28px;

      font-weight: 400;
      color: #ffffff;
    }

    .goindex {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 30px;
      width: 570px;
      height: 70px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      font-size: 28px;

      font-weight: 400;
      color: #000000;
    }
  }
}
.technicalSupport {
  background: #f6f6f6;
  padding: 30px 0 59px;
  font-size: 20px;
  color: #999999;
  line-height: 1;
  text-align: center;
}
.technicalSupport.successCss {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: transparent;
  width: 100%;
}
.humanMachineVBox {
  .humanMachineVImgBox {
    display: flex;
    padding: 0 70px;
    align-items: center;
    margin-bottom: 20px;
    img {
      flex: 1;
    }
    span {
      width: 120px;
      margin-left: 20px;
      font-size: 28px;
      text-decoration: underline;
      color: #419dfb;
    }
  }
  .humanMachineVBtnBox {
    display: flex;
    align-items: center;
    padding: 0 70px;
    input {
      height: 70px;
      border: solid 1px #dfdfdf;
      border: solid 1px #dfdfdf;
      border-radius: 6px;
      flex: 1;
      font-size: 28px;
      margin-right: 20px;
      &::placeholder {
        font-size: 22px;
        line-height: 70px;
      }
    }
    button {
      margin: auto;
      width: 120px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;

      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
